<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Centro de Custos Cadastrados</h4>
        <el-col :md="15">
          <el-row type="flex" justify="end">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="medium"
              @click="openCostCenterModal(null)"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <!--<el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="costs"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="name" label="nome"> </el-table-column>
      <el-table-column prop="entity" label="entidade"> </el-table-column>
      <el-table-column prop="category" label="categoria"> </el-table-column>
      <el-table-column label="ações" :width="100">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openCostCenterModal(cat.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteCostsCenter(cat.row)"
                title="
              Remover centro de custo?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>-->
    <el-tree
      :data="getCostsObjectTree()"
      v-if="costs?.length"
      node-key="id"
      default-expand-all
      :expand-on-click-node="false"
    >
      <template #default="{ node, data }">
        <el-row type="flex" justify="space-between" class="custom-tree-node">
          <el-row type="flex" justify="start">
            {{ node.label }}
            <small class="leaf-cost-type">{{
              getCostFromDotString(generateDotStringFromNode(node))
                ?.type_formatted
            }}</small>
          </el-row>
          <div>
            <i
              class="el-icon-plus btn-effect-primary"
              v-if="node.level < 4"
              @click="() => createCost(node, data)"
            />
            <i
              class="el-icon-edit btn-effect-primary"
              v-if="node.isLeaf"
              @click="
                () =>
                  openCostCenterModal(
                    getCostFromDotString(generateDotStringFromNode(node))
                  )
              "
            />
            <el-popconfirm
              @confirm="
                deleteCostsCenter(
                  getCostFromDotString(generateDotStringFromNode(node))
                )
              "
              v-if="node.isLeaf && canAny('DeleteCosts')"
              title="
              Remover centro de custo?
            "
              ><template #reference>
                <i class="el-icon-delete btn-effect-danger" /></template
            ></el-popconfirm>
          </div>
        </el-row>
      </template>
    </el-tree>
    <el-empty v-else description="Nenhum centro de custo foi cadastrado">
    </el-empty>
    <cost-modal
      :showModal="showCostsCenterModal"
      :cost="cost"
      :costs="costs"
      @close-modal="showCostsCenterModal = false"
      @should-update="fetchCostsCenter"
    ></cost-modal>
  </el-card>
</template>

<script>
import CostModal from "./modals/CostModal.vue";
import {
  generateDotStringFromCost,
  convertKeysToLabels,
  generateObjectFromDotString,
} from "../utils/functions";

export default {
  name: "CostCenterPage",
  components: { CostModal },
  data: () => ({
    hasError: false,
    isLoading: true,
    costs: null,
    cost: null,
    showCostsCenterModal: false,
  }),
  watch: {
    costs(v) {
      if (v?.length) {
        for (let x of v) {
          x.dotString = generateDotStringFromCost(x);
        }
      }
    },
  },
  mounted() {
    this.fetchCostsCenter();
  },
  computed: {
    can() {
      return this.$store.state.auth.can;
    },
  },
  methods: {
    canAny() {
      for (let a in arguments) if (this.can(arguments[a])) return true;

      return false;
    },
    getCostsTree() {
      return this.costs
        ?.map((c) => c.dotString)
        ?.reduce((t, c) => generateObjectFromDotString(t, c), {});
    },
    getCostFromDotString(dotString) {
      return this.costs.find((c) => c.dotString === dotString);
    },
    generateDotStringFromNode(node) {
      let parent = node.parent;
      let _ = node.data.label;
      while (parent) {
        if ("label" in parent?.data) _ = `${parent?.data?.label}.${_}`;
        parent = parent.parent;
      }
      return _;
    },
    getCostsObjectTree() {
      const tree = this.getCostsTree();
      const _ = [];

      for (let p in tree) {
        _.push({ label: p, children: convertKeysToLabels(tree[p]) });
      }

      return _.flat();
    },
    deleteCostsCenter(cost) {
      fetch(`${this.$store.state.apiUrl}costs/${cost.uid}`, {
        credentials: "include",
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.text();
        })
        .then(() => {
          this.fetchCostsCenter();
          this.$notify({
            title: "Centro de Custo removido com sucesso.",
            type: "success",
            position: "bottom-right",
          });
        })
        .catch(async (e) => {
          this.$notify({
            title: "Não foi possível remover o Centro de Custo",
            type: "error",
            message: (await e).message,
            position: "bottom-right",
          });
        });
    },
    createCost(node) {
      let level = node.level;
      let _ = node;
      const fields = [];
      while (level--) {
        fields[level] = _.data.label;
        _ = _.parent;
      }

      this.openCostCenterModal({
        category: fields[0],
        subcategory: fields[1],
        name: fields[2],
        entity: fields[3],
      });
    },
    openCostCenterModal(e) {
      (this.cost = e), (this.showCostsCenterModal = true);
    },
    fetchCostsCenter() {
      fetch(`${this.$store.state.apiUrl}costs`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.costs = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
.custom-tree-node {
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.btn-effect-primary {
  color: #409eff;
}
.leaf-cost-type {
  margin-left: 24px;
  align-self: center;
  color: #409eff;
}
.btn-effect-primary:hover {
  color: #66b1ff;
}
.btn-effect-danger {
  color: #f23c3c;
}
.btn-effect-danger:hover {
  color: #f78989;
}
</style>