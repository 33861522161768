<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <el-row type="flex">
        <el-col> </el-col>
      </el-row>
      <el-row type="flex" justify="start">
        <h4>Identificação:</h4>
      </el-row>
      <el-select
        v-model="cost_.category"
        clearable
        filterable
        allow-create
        size="medium"
        placeholder="Categoria do centro de custo"
      >
        <template #prefix> Nível 1: </template>
        <el-option
          v-for="item in getLevelListByKey('category')"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="cost_.subcategory"
        clearable
        filterable
        allow-create
        size="medium"
        placeholder="Subcategoria do centro de custo"
      >
        <template #prefix> Nível 2: </template>
        <el-option
          v-for="item in getLevelListByKey('subcategory')"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="cost_.name"
        clearable
        filterable
        allow-create
        size="medium"
        placeholder="Nome do centro de custo"
      >
        <template #prefix> Nível 3: </template>
        <el-option
          v-for="item in getLevelListByKey('name')"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="cost_.entity"
        clearable
        filterable
        allow-create
        size="medium"
        placeholder="Entidade do centro de custo"
      >
        <template #prefix> Nível 4: </template>
        <el-option
          v-for="item in getLevelListByKey('entity')"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="cost_.type"
        clearable
        size="medium"
        default-first-option
        placeholder="Investimento, custo ou despesa?"
      >
        <template #prefix> Tipo do Custo </template>
        <el-option
          v-for="item in CostTypes"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
//import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";

export default {
  props: ["cost", "showModal", "costs"],
  //components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      cost_: { ...(this.cost || {}) },
      isLoadingSave: false,
      entities: [],
      cost_categories: null,
      systemActions: [],
    };
  },
  watch: {
    cost(v) {
      this.cost_ = { ...(v || {}) };
    },
  },
  computed: {
    CostCategory() {
      return this.cost_categories;
    },
    Entities() {
      return this.entities || [];
    },
    isNew() {
      return !("uid" in (this.cost || {}));
    },
    ModalTitle() {
      return this.isNew
        ? "Cadastrar centro de custo"
        : "Editar centro de custo";
    },
    CostTypes() {
      return [
        {
          label: "Investimento",
          value: "investiment",
        },
        {
          label: "Custo",
          value: "cost",
        },
        {
          label: "Despesa",
          value: "expense",
        },
      ];
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  mounted() {
    this.fetchEntities();
  },
  methods: {
    save() {
      this.isLoadingSave = true;
      return this.isNew ? this.createCost() : this.updateCost();
    },
    getLevelListByKey(key) {
      return Object.keys(
        this.costs
          ?.map((c) => c[key])
          ?.filter((c) => c)
          ?.reduce((t, e) => {
            t[e] = true;
            return t;
          }, {}) || {}
      );
    },
    fetchCostCategories() {
      fetch(`${this.$store.state.apiUrl}cost-categories`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.cost_categories = json));
    },
    fetchEntities() {
      fetch(`${this.$store.state.apiUrl}entities/all`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.entities = json));
    },
    createCost() {
      fetch(`${this.$store.state.apiUrl}costs`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(this.cost_),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            this.shouldShow = false;
            this.cost_ = {};
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível cadastrar o Centro de Custo",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
        });
    },
    updateCost() {
      fetch(`${this.$store.state.apiUrl}costs/${this.cost_.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({ ...this.cost_ }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível alterar o Centro de Custo",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
          this.shouldShow = false;
        });
    },
  },
  name: "CostModal",
};
</script>
<style>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>